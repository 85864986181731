import Default from 'gatsby-theme-carbon/src/templates/Default';
import {UserContext, handleLogin, isLoggedIn} from '@parallelpublicworks/entitysync';
import { Link, navigate } from "gatsby"
import { Button, Form, TextInput, ToastNotification} from 'carbon-components-react';
import React, {useContext, useEffect, useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'
import { SetNewPasswordForm } from './password-reset'
import {
  siteMetadata
} from "../../gatsby-config"

export default function PasswordSetPage({pageContext, location}) {
  pageContext = {...pageContext, frontmatter: {title: "Reset Password"}};

  return (
    <Default pageContext={pageContext} location={location}>
      <SetNewPasswordForm />
    </Default>    
  )
}
